import "./Pagination.css";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";

function Pagination({
  totalPageNumber,
  handlePageChange,
  currentPage,
  totalItems,
}) {
  const totalPages = totalPageNumber;

  console.log(totalPages);

  const generatePages = () => {
    const pages = [];

    // Add first page
    pages.push(1);

    // Add ellipsis if needed before currentPage - 2
    if (currentPage > 4) {
      pages.push("...");
    }

    // Add visible page numbers
    for (
      let i = Math.max(2, currentPage - 2);
      i <= Math.min(totalPages - 1, currentPage + 2);
      i++
    ) {
      pages.push(i);
    }

    // Add ellipsis if needed after currentPage + 2
    if (currentPage < totalPages - 3) {
      pages.push("...");
    }

    // Add last page
    if (totalPages > 1) {
      pages.push(totalPages);
    }

    return pages;
  };

  const handlePageClick = (page) => {
    if (page !== "...") {
      handlePageChange(page);
    }
  };

  return (
    <div className="pagination-container">
      {/* left side */}
      {/* <div className="pagination-info">
        {
        "Show " 
        + ((currentPage - 1) * 10 + 1) +
        " to " +  
        (totalItems - ((totalPageNumber - currentPage) * 10)) +
         " of " +  
         totalItems  + 
         " entries" }
      </div> */}
      <div className="pagination-info">
        {`Show ${(currentPage - 1) * 10 + 1} to ${Math.min(
          currentPage * 10,
          totalItems
        )} of ${totalItems} entries`}
      </div>
      {/* pagination  */}
      <div className="pagination">
        <button
          onClick={() => handlePageClick(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <RiArrowLeftSLine />
        </button>
        {generatePages().map((page) => (
          <button
            key={page}
            onClick={() => handlePageClick(page)}
            className={currentPage === page ? "active" : ""}
          >
            {page}
          </button>
        ))}
        <button
          className="prev-next-btn"
          onClick={() => handlePageClick(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <RiArrowRightSLine />
        </button>
      </div>
    </div>
  );
}

export default Pagination;
